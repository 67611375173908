<template lang="pug">
.stats.fixed
  span.fps(ref='stats') 0
canvas.canvas.fixed(ref='canvas')
</template>

<script>
import createREGL from 'regl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import mat4 from 'gl-mat4';

import vert from '@/shaders/particle-cylinder.vert';
import frag from '@/shaders/particle-cylinder.frag';

function getInitialPoints({ nRow, nCol }) {
  const positions = [];
  for (let row = 0; row < nRow; row += 1) {
    for (let col = 0; col < nCol; col += 1) {
      const alpha = Math.random() * 2 * Math.PI;
      positions.push([alpha, -Math.random() * 1.5]);
      // positions.push([alpha, -(row / nRow)]);
    }
  }
  return { positions, count: positions.length };
}

export default {
  name: 'ParticleCylinder',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const stats = ref(null);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    const Z_DEPTH = 200;
    const R = 500;
    const ROW_N = 30;
    const ROW_GAP = Z_DEPTH / ROW_N;
    const COL_N = 250;
    const initialPoints = getInitialPoints({
      nRow: ROW_N,
      nCol: COL_N,
      rowGap: ROW_GAP / Z_DEPTH,
    });
    mat4;
    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });

      const draw = regl({
        vert,
        frag,
        context: {
          projection: ({ viewportWidth, viewportHeight }) => {
            const scale = viewportWidth > 1024 ? 2 : 1;
            return mat4.ortho(
              [],
              -viewportWidth / scale,
              viewportWidth / scale,
              viewportHeight / scale,
              -viewportHeight / scale,
              0.01,
              10000,
            );
            // return mat4.perspective([], Math.PI / 6, viewportWidth / viewportHeight, 0.01, 10000);
          },
          eye: ({ tick }) => {
            const t = 0.001 * tick;
            // return [4, 0, 0];
            return [0, 0, 2000];
            // return [3, 3000 * Math.cos(t), 3000 * Math.sin(t)];
          },
        },
        uniforms: {
          projection: ({ projection }) => projection,
          model: mat4.identity([]),
          view: ({ eye }) => mat4.lookAt([], eye, [0, 0, 0], [0, 1, 0]),
          time: regl.prop('time'),
          radius: R,
          zDepth: Z_DEPTH,
          pSize: ({ viewportWidth }) => {
            return viewportWidth >= 1024 ? 3 : 3;
          },
        },
        attributes: {
          position: initialPoints.positions,
        },
        count: initialPoints.count,
        primitive: 'points',
        depth: { enable: false },
        blend: {
          enable: false,
          func: {
            srcRGB: 'src alpha',
            srcAlpha: 'src alpha',
            dstRGB: 'one minus src alpha',
            dstAlpha: 'one minus src alpha',
          },
        },
      });

      let t;
      let c = 0;
      const update = ({ time }) => {
        draw({ time });

        if (!t) {
          t = time;
        }
        c += 1;
        if (c >= 60) {
          const fps = c / (time - t);
          c = 0;
          t = time;
          stats.value.textContent = fps;
        }
      };
      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, stats };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  cursor pointer
  background #000

  img
    display none

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0
</style>
